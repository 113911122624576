'use client'

import React, { useState, useEffect } from 'react';
import { usePathname } from 'next/navigation';
import { BiArrowToTop } from 'react-icons/bi';

// 不顯示 GO TOP BUTTON
const shouldHideButtonRoutes = ['/escaperoommap'];

const GoTopButton = () => {
  const [isShow, setIsShow] = useState(false);

  const pathname = usePathname();

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);

  const handleScroll = () => {
    setIsShow(!shouldHideButtonRoutes.includes(pathname) && (window.scrollY > window.innerHeight * 0.3))
  };

  const handleClick = () => {
    const target = document.getElementById("top");

    window.scrollTo({ 
      'behavior': 'smooth', 
      'top': target?.offsetTop || 0
    })
  }

  return (
    <div 
      className="fixed right-2 md:right-4 bottom-20 md:bottom-10 z-[100]"
      style={{ display: isShow ? "block" : "none" }}
    >
      <div 
        className="w-8 md:w-10 h-8 md:h-10 flex justify-center items-center bg-primary text-white text-2xl rounded shadow-lg opacity-80 md:opacity-70 hover:opacity-100 cursor-pointer"
        onClick={handleClick}
      >
        <BiArrowToTop />
      </div>
    </div>
  )
};

export default GoTopButton;