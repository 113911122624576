'use client'

import React from 'react';
import clsx from 'clsx';
import { AiFillHome, AiOutlineHome } from 'react-icons/ai';
import { BiBuildings } from 'react-icons/bi';
import { BsPersonFill, BsPerson } from 'react-icons/bs';
import { FaMap, FaRegMap } from 'react-icons/fa';
import { MdExplore } from 'react-icons/md';
import { useRouter, usePathname } from 'next/navigation';
import { useUser, useGlobal } from 'src/context';
import { trackEvent } from 'utils';

const BottomMenu = () => {
  const { isStandalone, onOpenLogin } = useGlobal();
  const { isLogin, isLoadingUser, user: { customId } } = useUser();

  const router = useRouter();

  const handleBtnClick = tabName => {
    switch (tabName) {
      case "Game": 
        trackEvent({
          category: 'Buttom_Menu-Item',
          action: 'Click',
          label: '遊戲'
        });
        router.push(`/games`);
        break;
      case "Firm":
        trackEvent({
          category: 'Buttom_Menu-Item',
          action: 'Click',
          label: '店家'
        });
        router.push(`/firms`);
        break;
      case "Map":
        trackEvent({
          category: 'Buttom_Menu-Item',
          action: 'Click',
          label: '地圖'
        });
        router.push(`/escaperoommap`);
        break;
      case "User":
        trackEvent({
          category: 'Buttom_Menu-Item',
          action: 'Click',
          label: '我的'
        });

        if (isLoadingUser) {
          return;
        } else if (isLogin && customId) {
          router.push(`/user/${customId}`);
        } else {
          onOpenLogin();
        }
        break;
      case "Home":
      default:
        trackEvent({
          category: 'Buttom_Menu-Item',
          action: 'Click',
          label: '首頁'
        });
        router.push("/");
        break;
    }
  };

  return (
    <nav className={clsx("fixed md:hidden bottom-0 left-0 z-[1299] w-screen pb-0 flex justify-between bg-neutral-50 shadow-lg backdrop-blur-sm", { "pb-5": isStandalone })}>
      <NavButton
        href="/"
        target="Home"
        label="首頁"
        onClick={handleBtnClick}
        icon={<AiOutlineHome />}
        activeIcon={<AiFillHome />}
      />
      <NavButton
        href="/escaperoommap"
        target="Map"
        label="地圖"
        onClick={handleBtnClick}
        icon={<FaRegMap />}
        activeIcon={<FaMap />}
      />
      <NavButton
        href="/games"
        target="Game"
        label="遊戲"
        onClick={handleBtnClick}
        icon={<MdExplore />}
      />
      <NavButton
        href="/firms"
        target="Firm"
        label="店家"
        onClick={handleBtnClick}
        icon={<BiBuildings />}
      />
      <NavButton
        href="/user"
        target="User"
        label="我的"
        disabled={isLoadingUser}
        onClick={handleBtnClick}
        icon={<BsPerson />}
        activeIcon={<BsPersonFill />}
      />
    </nav>
  )
};

const NavButton = ({ href, target, label, disabled = false, icon, activeIcon = null, onClick }) => {
  const { user: { customId } } = useUser();

  const pathname = usePathname();

  const isActive = href === "/" 
    ? !!(href === pathname) 
    : href === "/user" 
      ? pathname.startsWith(`/user/${customId}`) 
      : pathname.startsWith(href);

  return (
    <button 
      className={`w-1/5 p-[2px] flex flex-col justify-center items-center gap-1 ${isActive ? "text-primary" : "text-neutral-900"} ${disabled ? "text-neutral-400 cursor-not-allowed" : ""}`}
      onClick={() => onClick(target)}
    >
      <span className="text-xl">
        {isActive && activeIcon ? activeIcon : icon}
      </span>
      <span className="text-xs">
        {label}
      </span>
    </button>
  )
};

export default BottomMenu;