'use client'

import React, { useState, useEffect, useMemo, useRef } from 'react';
import { 
  Avatar, 
  Button,
  IconButton,
  Checkbox,
  CloseButton,
  InputGroup,
  Input,
  InputRightElement,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuGroup,
  Portal,
  useDisclosure
} from '@chakra-ui/react'
import { BiUserCircle, BiSearch } from 'react-icons/bi';
import { FaBell } from "react-icons/fa";
import clsx from 'clsx'
import Link from 'next/link';
import dynamic from 'next/dynamic';
import { useRouter, usePathname, useSearchParams } from 'next/navigation'
import { useUser, useGlobal } from 'src/context';
import { trackEvent } from 'utils';
import AlgoliaAutocomplete from './AlgoliaAutocomplete';
import NotificationCenter from './header/NotificationCenter';
const PwaNavBar = dynamic(() => import('./header/PwaNavBar'));
const LoginDialog = dynamic(() => import('./header/LoginDialog'))
const LogoutDialog = dynamic(() => import('./header/LogoutDialog'));

const LOCAL_STORAGE_COVER_KEY = "cover";
const COVER_INTERVAL = 60 * 60 * 12;

// desktop: logo 在左，接續各主要頁面捷徑。搜尋bar與 user menu在右。
// mobile:  logo 在左。搜尋bar與 user menu在右。

const Header = () => {
  const { onInstall } = useGlobal();

  // const [isComponentReady, setIsComponentReady] = useState(false);  // necessary ?

  const { 
    isOpen: isNotificationOpen, 
    onOpen: onNotificationOpen, 
    onClose: onNotificationClose 
  } = useDisclosure()

  const pathname = usePathname();

  const isGamesPage = useMemo(() => {
    return pathname.startsWith("/games");
  }, [pathname]);


  const handleItemClick = label => {
    trackEvent({ category: 'Header-Item', action: 'Click', label })
  };

  const handleInstallClick = () => {
    trackEvent({ category: 'Install_PWA--Menu-Item', action: 'Click', label: "下載 APP", value: 2 })

    onInstall();
  };

  return (
    <nav className='w-full h-full lg:max-w-screen-lg xl:max-w-screen-xl 2xl:max-screen-2xl mx-auto flex justify-between items-center'>
      <div className='flex items-center'>
        <Link href="/" className='mr-4'>
          <img 
            className='w-36'
            src="/img/logo-header.svg" 
          />
        </Link>
        <div className='hidden md:flex gap-4 text-white font-bold tracking-wider'>
          <Link 
            href="/games" 
            className={`pt-3 pb-2 border-b-2 border-transparent ${isGamesPage ? "border-white" : ""} hover:border-white`}
            onClick={() => handleItemClick('找好遊戲')}
          >
            找好遊戲
          </Link>
          <Link 
            href="/firms" 
            className={`pt-3 pb-2 border-b-2 border-transparent ${pathname === "/firms" ? "border-white" : ""} hover:border-white`}
            onClick={() => handleItemClick('尋工作室')}
          >
            尋工作室
          </Link>
          <Link 
            href="/escaperoommap" 
            className={`pt-3 pb-2 border-b-2 border-transparent ${pathname === "/escaperoommap" ? "border-white" : ""} hover:border-white`}
            onClick={() => handleItemClick('密室地圖')}
          >
            密室地圖
          </Link>
          <Link 
            href="/posts" 
            className={`pt-3 pb-2 border-b-2 border-transparent ${pathname === "/posts" ? "border-white" : ""} hover:border-white`}
            onClick={() => handleItemClick('主題文章')}
          >
            主題文章
          </Link>
          <Link 
            href="/achievements" 
            className={`pt-3 pb-2 border-b-2 border-transparent ${pathname === "/achievements" ? "border-white" : ""} hover:border-white`}
            onClick={() => handleItemClick('成就大賞')}
          >
            成就大賞
          </Link>
          <Link 
            href="/event/goty2023" 
            className={`pt-3 pb-2 border-b-2 border-transparent ${pathname === "/event/goty2023" ? "border-white" : ""} hover:border-white`}
            onClick={() => handleItemClick('金逃獎')}
          >
            金逃獎
          </Link>
          <Link 
            href="/event/lantern2025" 
            className={`pt-3 pb-2 border-b-2 border-transparent ${pathname === "/event/lantern2025" ? "border-white" : ""} hover:border-white`}
            onClick={() => handleItemClick('猜燈謎')}
          >
            猜燈謎
          </Link>
        </div>
      </div>
      <div className='flex gap-x-4 items-center'>
        <AlgoliaAutocomplete />
        <NotificationButton onOpen={onNotificationOpen} />
        <AvatarButton />
      </div>
      <NotificationCenter 
        isOpen={isNotificationOpen}
        onClose={onNotificationClose}
      />
      <PwaNavBar onNotificationOpen={onNotificationOpen} />
      <LoginDialog />
      <LogoutDialog />
      <Cover />
    </nav>
  )
};

// const SearchBar = () => {
//   const [value, setValue] = useState('');
  
//   const router = useRouter();

//   const handleChange = (event) => setValue(event.target.value);

//   const handleKeyDown = e => {
//     if(e.keyCode === 13) {
//       handleSearch()
//     }
//   };

//   const handleSearch = () => {
//     const trimmedKeyword = value.trim();

//     trackEvent({ category: 'Header-Search', action: 'Search', label: trimmedKeyword });

//     if (trimmedKeyword.length === 0) return;

//     router.push(`/games?outdoor=true&over=true&q=${trimmedKeyword}`)
//   };

//   return (
//     <InputGroup>
//       <Input
//         value={value}
//         onChange={handleChange}
//         onKeyDown={handleKeyDown}
//         placeholder='輸入關鍵字...' 
//         _placeholder={{ color: 'white' }}
//         variant='filled'
//         isInvalid={value.length === 1}
//         errorBorderColor='crimson'
//         size='sm'
//         px={1}
//         bg='gray.500'
//         color='white'
//         _focus={{ borderColor: 'white', boxShadow: 'none' }}
//       />
//       <InputRightElement 
//         sx={{ height: '2rem', color: 'white', fontSize: '1.25rem' }}
//         _hover={{ cursor: "pointer" }}
//         onClick={handleSearch}
//       >
//         <BiSearch />
//       </InputRightElement>
//     </InputGroup>
//   )
// };

const NotificationButton = ({ onOpen }) => {
  const { isLogin, isLoadingUser, isPostingLogout, hasNewNotification, setHasNewNotification } = useUser();

  const disabled = isLoadingUser || isPostingLogout || !isLogin;

  const handleOpen = () => {
    if (disabled) return;
    onOpen()
    setHasNewNotification(false);
  }

  return (
    <div className='relative'>
      <IconButton
        isRound
        variant='solid'
        colorScheme='whiteAlpha'
        aria-label='notifications'
        fontSize={{ base: '16px', md: '20px' }}
        icon={<FaBell />}
        w={{ base: '1.5rem', md: '2rem' }}
        minW={{ base: '1.5rem', md: '2rem' }}
        h={{ base: '1.5rem', md: '2rem' }}
        onClick={handleOpen}
        isDisabled={disabled}
      />
      <span className={clsx("absolute -right-1 w-3 h-3 rounded-full", { 'bg-red-500': hasNewNotification } )} />
    </div>
  )
};

const AvatarButton = () => {
  const { onOpenLogin, onOpenLogout } = useGlobal();
  const { isLogin, isLoadingUser, isPostingLogout, user } = useUser();
  const { displayName, photoUrl, customId } = user;

  const handleClick = () => {
    trackEvent({ category: 'Header-Item', action: 'Click', label: "會員中心" })
  }

  const handleLogin = () => {
    trackEvent({
      category: 'Header-Item',
      action: 'Click',
      label: '註冊/登入'
    });

    onOpenLogin();
  };

  const handleLogout = () => {
    trackEvent({
      category: 'Header-Item',
      action: 'Click',
      label: '登出'
    });
  
    onOpenLogout()
  }

  if (isLoadingUser || isPostingLogout) {
    return <Avatar size={["xs", "xs", "sm"]} />
  }

  if (isLogin) {
    return (
      <Menu>
        <MenuButton>
          <Avatar 
            size={["xs", "xs", "sm"]} 
            src={photoUrl} 
            name={displayName} 
          />
        </MenuButton>
        <MenuList zIndex={100}>
          <MenuGroup title={displayName} />
          <Link href={`/user/${customId}`} onClick={handleClick}>
            <MenuItem>會員中心</MenuItem>
          </Link>
          <MenuItem onClick={handleLogout}>登出</MenuItem>
        </MenuList>
      </Menu>
    )
  } else {
    return (
      <Button 
        size={["xs", "xs", "xs", "sm"]} 
        rightIcon={<BiUserCircle />} 
        colorScheme='whiteAlpha'
        color='whiteAlpha.900'
        variant='ghost'
        onClick={handleLogin}
      >
        登入
      </Button>
    )
  }
};

const Cover = () => {
  const [isShowCover, setIsShowCover] = useState(false);
  const [countdownSeconds, setCountdownSeconds] = useState(7);
  // const [isDeferCheck, setIsDeferCheck] = useState(false);
  const [imageSource, setImageSource] = useState(null);
  const [isHrefOutbound, setIsHrefOutbound] = useState(false);

  const { coverData } = useGlobal();

  const coverCounter = useRef(null);
  const coverHref = useRef(null);
  
  const pathname = usePathname();
  const searchParams = useSearchParams();

  useEffect(() => {
    if (coverData?.length) {
      const columns = coverData.length;
      const links = coverData.reduce((accu, col) => {
        const srcLinks = col.source.map(src => src.link);
        return [...accu, ...srcLinks]
      }, [])

      const currentTime = Date.now() / 1000;
      
      if (searchParams.ivtId || links.indexOf(pathname) > -1 || pathname.startsWith("/event")) return;

      let prevId = "", prevShowTime = 0, nextImageSource = "", nextHref = "";

      // 1. 先確認先前是否已顯示過廣告，若先前有顯示過廣告，在 COVER_INTERVAL 內的話不顯示廣告
      const coverDataInStorage = localStorage.getItem(LOCAL_STORAGE_COVER_KEY);
      if (coverDataInStorage) {
        const coverRecord = JSON.parse(coverDataInStorage);
        prevId = coverRecord.id;
        prevShowTime = coverRecord.showTime;

        if (prevShowTime + COVER_INTERVAL > currentTime) return;
      }

      if (prevId) {
        // 2. 若有先前顯示過廣告的紀錄，判斷是否需要顯示與上次不同的
        const prevColumnIndex = coverData.findIndex(column => {
          return column.source.some(src => {
            const { image_outside, image } = src;
            return [image_outside, image].indexOf(prevId) > -1
          })
        })

        if (columns > 1) {
          // 2.1 若此時有其他廣告，則從其他廣告選一則
          const columnIndex = getCoverColumnIndex(coverData, prevColumnIndex)
          const { imgSrc, link } = getCoverSource(coverData, columnIndex);

          nextImageSource = imgSrc;
          nextHref = link;
        } else {
          // 2.2 若此時無其他廣告，則在同一檔中隨機選一款素材
          const { imgSrc, link } = getCoverSource(coverData, 0);

          nextImageSource = imgSrc;
          nextHref = link;
        }
      } else {
        // 3. 若先前沒顯示過廣告，隨機選一檔在走期當中的版位的第 0 款素材
        const columnIndex = getCoverColumnIndex(coverData)
        const { imgSrc, link } = getCoverSource(coverData, columnIndex);

        nextImageSource = imgSrc;
        nextHref = link;
      }

      saveCoverRecord(nextImageSource);
      coverHref.current = nextHref;
      setIsHrefOutbound(nextHref.startsWith("https://"))
      setImageSource(nextImageSource);

      // 3 秒後顯示，並且倒數自動關閉
      coverCounter.current = setTimeout(() => {
        setIsShowCover(true);
      }, 1000 * 3);
    }
  }, [coverData]);

  useEffect(() => {
    if (isShowCover) {
      trackEvent({
        category: 'Cover',
        action: 'Impression',
        label: imageSource
      });
    }
  }, [isShowCover])

  useEffect(() => {
    if (isShowCover) {
      if (countdownSeconds > 0) {
        coverCounter.current = setTimeout(() => {
          setCountdownSeconds(prevValue => prevValue - 1);
        }, 1000);
      } else {
        handleCoverClose();
      }
    }
  }, [isShowCover, countdownSeconds])

  const handleCoverImageClick = () => {
    trackEvent({
      category: 'Cover',
      action: 'Click',
      label: imageSource
    });

    handleCoverClose();
  };

  const handleCoverClose = () => {
    coverCounter.current = null;
    coverHref.current = null;

    setIsShowCover(false);
    setImageSource(null);
  };

  if (!isShowCover) return null;

  return (
    <Portal>
      <div className="fixed top-0 left-0 z-[9999] w-screen h-screen pt-[60px] flex justify-center items-center bg-[#00000090] backdrop-blur-sm">
        <div className="relative w-full h-full flex flex-col items-center md:m-auto md:w-max-[600px]">
          <p className='mb-8 text-white text-xl tracking-wider'>將於 {countdownSeconds} 秒後自動關閉... </p>
          {isHrefOutbound ? (
            <a href={coverHref.current} target="blank">
              <img 
                className='w-full max-w-[600px]'
                src={imageSource} 
                onClick={handleCoverImageClick} 
              />
            </a>
          ) : (
            <Link href={coverHref.current}>
              <img 
                className='w-full max-w-[600px]'
                src={imageSource} 
                onClick={handleCoverImageClick} 
              />
            </Link>
          )}
          {/* {coverData?.is_deferrable && (
            <p className="mt-8 mb-0 text-white">
              <Checkbox
                colorScheme="primary"
                isChecked={isDeferCheck}
                onChange={() => setIsDeferCheck(!isDeferCheck)}
              />
              <span>7 日內不再顯示</span>
            </p>
          )} */}
          <div className='absolute right-5 sm:right-10 lg:right-20 top-0 z-10 '>
            <CloseButton 
              size='lg'
              bg='white'
              onClick={handleCoverClose} 
            />
          </div>
        </div>
      </div>
    </Portal>
  )
};

function getCoverColumnIndex (coverData, prevIndex = null) {
  const columns = coverData.length;

  let index = 0;

  if (columns > 1) {
    index = Math.floor(Math.random() * columns);
  }

  if (prevIndex !== null && index === prevIndex) {
    index += 1;
    if (index > columns - 1) index = 0;
  }

  return index;
}

function getCoverSource (coverData, columnIndex) {
  const { source } = coverData[columnIndex];

  let index = 0;

  if (source.length > 1) {
    index = Math.floor(Math.random() * source.length);
  }

  const { image_outside, image, link } = source[index]

  return {
    imgSrc: image_outside || image,
    link,
  }
}

function saveCoverRecord (id) {
  const currentTime = Date.now() / 1000;

  const record = {
    id,
    showTime: currentTime + COVER_INTERVAL
  }

  localStorage.setItem(LOCAL_STORAGE_COVER_KEY, JSON.stringify(record));
};

export default Header;