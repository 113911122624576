import React, { createElement, Fragment, useEffect, useRef } from 'react';
import { createRoot } from 'react-dom/client';
import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { liteClient as algoliasearch } from 'algoliasearch/lite';
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js';
import clsx from 'clsx';
import '@algolia/autocomplete-theme-classic';
import { allCityCodeMap, trackEvent } from 'utils';

const searchClient = algoliasearch(process.env.NEXT_PUBLIC_ALGOLIA_APP_ID, process.env.NEXT_PUBLIC_ALGOLIA_API_KEY);

function debouncePromise(fn, time) {
  let timer = undefined;

  return function debounced(...args) {
    if (timer) {
      clearTimeout(timer);
    }

    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(fn(...args)), time);
    });
  };
}

const DEBOUNCE_MS = 750;

function getGameTypeLabelColor (gameType) {
  if (gameType === '實境解謎') {
    return 'text-yellow-500 border-yellow-500';
  } else if (gameType === '角色扮演') {
    return 'text-green-500 border-green-500';
  } else if (gameType === '沈浸劇場') {
    return 'text-purple-500 border-purple-500';
  } else if (gameType === '大型團體') {
    return 'text-pink-500 border-pink-500';
  } else {
    return 'text-blue-500 border-blue-500';
  }
}

function GameItem({ hit, components }) {
  const { objectID, cityId, gameType, prominence } = hit;
  const labelStyle = getGameTypeLabelColor(gameType);
  return (
    <Link href={`/game/${objectID}`}>
      <div className="flex items-center gap-2">
        <div className="flex flex-col">
          <p className={`px-1 py-0.5 text-xs border rounded-md ${labelStyle}`}>
            {gameType}
          </p>
          <p className="ml-1 text-sm text-gray-500">
            {allCityCodeMap[cityId] || ''}
          </p>
        </div>
        <div className="flex flex-col flex-1">
          <p className="mt-0.5 mb-1 font-bold">
            <components.Highlight hit={hit} attribute="title" />
          </p>
          <p className="text-sm text-gray-500">
            <components.Highlight hit={hit} attribute="primaryFirmName" />
          </p>
        </div>
        <span className="w-24 sm:w-44 md:w-56 text-sm text-secondary font-semibold">
          {prominence}
        </span>
      </div>
    </Link>
  );
}

function FirmItem({ hit, components }) {
  const { objectID, firmLogo } = hit;

  return (
    <Link href={`/firm/${objectID}`}>
      <div className="flex items-center gap-2 group">
        <img 
          src={firmLogo.thumbnail} 
          alt={firmLogo.alt} 
          className="w-8 h-8 rounded-full"
        />
        <p className="flex-1">
          <components.Highlight hit={hit} attribute="title" />
        </p>
      </div>
    </Link>
  );
}

function Autocomplete(props) {
  const router = useRouter();
  const containerRef = useRef(null);
  const panelRootRef = useRef(null);
  const rootRef = useRef(null);

  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }

    const debounced = debouncePromise((items) => Promise.resolve(items), DEBOUNCE_MS);

    const search = autocomplete({
      container: containerRef.current,
      placeholder: props.placeholder || (window.innerWidth < 640 ? '搜尋' : '輸入關鍵字...'),
      translations: {
        detachedCancelButtonText: '取消',
      },
      navigator: {
        navigate({ itemUrl }) {
          router.push(itemUrl);
        },
      },
      renderer: { createElement, Fragment, render: () => {} },
      render({ children }, root) {
        if (!panelRootRef.current || rootRef.current !== root) {
          rootRef.current = root;

          panelRootRef.current?.unmount();
          panelRootRef.current = createRoot(root);
        }

        panelRootRef.current.render(children);
      },
      detachedMediaQuery: '',
      openOnFocus: true,
      insights: true,
      classNames: {
        detachedSearchButton: '!h-9',
        detachedSearchButtonIcon: '!text-secondary',
        form: '!border-primary',
      },
      onSubmit: ({ state, event }) => {
        const query = state.query;

        trackEvent({ category: props.source || 'Header-Search', action: 'Search', label: query });

        if (query.length === 0) return;
    
        router.push(`/games?outdoor=true&over=true&q=${query}`)
      },
      getSources: ({ query }) => debounced([
        {
          sourceId: 'games',
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: 'games_index',
                  params: {
                    query,
                    hitsPerPage: 5,
                    attributesToRetrieve: ['objectID', 'title', 'cityId', 'gameType', 'primaryFirmName', 'prominence'],
                    attributesToHighlight: ['title', 'primaryFirmName'],
                    clickAnalytics: true,
                  },
                },
              ],
            });
          },
          getItemUrl({ item }) {
            return `/game/${item.objectID}`;
          },
          onSelect({ item }) {
            trackEvent({ 
              category: props.source || 'Header-Search', 
              action: 'Click-Autocomplete-Result', 
              label: `GAME-${item.objectID}-${item.title}` 
            });
          },
          templates: {
            header({ items }) {
              if (items.length === 0) return null;
              
              return (
                <div className="mb-2 flex items-center justify-between">
                  <p className="mr-2 text-sm font-bold text-primary">遊戲主題</p>
                  <hr className="flex-grow my-1 border-gray-200" />
                </div>
              );
            },
            item({ item, components }) {
              return <GameItem hit={item} components={components} />;
            },
            noResults() {
              return '沒有找到相關遊戲主題';
            },
          },
        },
        {
          sourceId: 'firms',
          getItems() {
            return getAlgoliaResults({
              searchClient,
              queries: [
                {
                  indexName: 'firms_index',
                  params: {
                    query,
                    hitsPerPage: 2,
                    attributesToRetrieve: ['objectID', 'title', 'firmLogo'],
                    attributesToHighlight: ['title'],
                    clickAnalytics: true,
                  },
                },
              ],
            });
          },
          getItemUrl({ item }) {
            return `/firm/${item.objectID}`;
          },
          onSelect({ item }) {
            trackEvent({ 
              category: props.source || 'Header-Search', 
              action: 'Click-Autocomplete-Result', 
              label: `FIRM-${item.objectID}-${item.title}` 
            });
          },
          templates: {
            header({ items }) {
              if (items.length === 0) return null;
              
              return (
                <div className="my-2 flex items-center justify-between">
                  <p className="mr-2 text-sm font-bold text-primary">工作室</p>
                  <hr className="flex-grow my-1 border-gray-200" />
                </div>
              );
            },
            item({ item, components }) {
              return <FirmItem hit={item} components={components} />;
            },
            noResults() {
              return '沒有找到相關工作室';
            },
          },
        },
      ]),
    });

    return () => {
      search.destroy();
    };
  }, [props, router]);

  return <div ref={containerRef} className={clsx('w-24 sm:w-48', props.className)} />;
}

export default Autocomplete;