'use client'

import Link from 'next/link';
import { usePathname } from 'next/navigation'
import { useGlobal } from 'src/context'
import BottomMenu from './BottomMenu';
import { trackEvent, siteTitle } from 'utils';

const showFooterRouteOnMobile = ["/post", "/about"]; // 手機版僅在這些頁面顯示

const Footer = () => {
  const { isScreenLg, isStandalone } = useGlobal();

  const pathname = usePathname();

  const isShowFooterList = !isStandalone && (isScreenLg || pathname === "/" || showFooterRouteOnMobile.some(route => pathname.startsWith(route)));

  return(
    <div className='w-full p-0 text-white'>
      <div className='w-full lg:max-w-screen-lg xl:max-w-screen-xl md:m-auto md:flex justify-around'>
        <FooterList isShow={isShowFooterList} />
        <FollowUsContainer isShow={isShowFooterList} />
      </div>
      <Copyrights isShow={isShowFooterList} />
      <BottomMenu />
    </div>
  );
};

const LinkItem = ({ href, children }) => {
  const handleItemClick = e => {
    trackEvent({ category: 'Footer-Item', action: 'Click', label: e.target.innerText })
  };

  return (
    <Link href={href} className="text-sm md:text-base font-medium text-white underline-offset-4 hover:underline">
      <li onClick={handleItemClick}>
        {children}
      </li>
    </Link>
  )
};

const FooterList = ({ isShow = false }) => {
  if (!isShow) return null;

  return (
    <div className='flex flex-wrap md:flex-grow px-3 py-0 md:py-3 md:px-0'>
      <ul className="my-4 md:my-0 p-0 w-1/2 md:w-1/3 list-none">
        <li>
          <h6 className='mb-2 text-base md:text-lg font-bold'>主要功能</h6>
        </li>
        <LinkItem href='/games'>找遊戲</LinkItem>
        <LinkItem href='/firms'>找工作室</LinkItem>
        <LinkItem href='/escaperoommap'>用地圖找</LinkItem>
        <LinkItem href='/achievements'>成就大賞</LinkItem>
        <LinkItem href='/posts'>主題文章</LinkItem>
      </ul>
      <ul className="my-4 md:my-0 p-0 w-1/2 md:w-1/3 list-none">
        <li>
          <h6 className='mb-2 text-base md:text-lg font-bold'>關於密室逃脫</h6>
        </li>
        <LinkItem href='/post/what-is-real-escape-games'>什麼是密室逃脫</LinkItem>
        <LinkItem href='/post/how-to-play-real-escape-game-smart'>如何玩密室逃脫</LinkItem>
        <LinkItem href='/post/business-group-activity'>福委活動與教育訓練</LinkItem>
      </ul>
      <ul className="my-4 md:my-0 p-0 w-1/2 md:w-1/3 list-none">
        <li>
          <h6 className='mb-2 text-base md:text-lg font-bold'>關於逃脫吧</h6>
        </li>
        <LinkItem href='/about/escape-bar-introduction'>關於逃脫吧</LinkItem>
        <LinkItem href='/about/data-supplement'>資料誤植與補遺</LinkItem>
        <LinkItem href='/about/services-term'>服務條款</LinkItem>
        <LinkItem href='/about/privacy-policy'>隱私權政策</LinkItem>
        <LinkItem href='/about/taiwan-immersive-entertainment-association'>沈浸式體驗協會會員募集</LinkItem>
      </ul>
    </div>
  )
};

const FollowUsContainer = ({ isShow = false }) => {
  if (!isShow) return null;

  return (
    <div className='w-full md:w-80 py-3 px-3 md:px-0'>
      <h6 className='mb-2 text-lg font-bold text-center md:text-left'>追蹤我們獲得好玩資訊</h6>
      <div className='mb-2 flex justify-center md:justify-start gap-4'>
        <a href="https://www.facebook.com/escapebar.tw/" target="_blank">
          <img 
            className='w-12'
            src="/img/footer-facebook.png"/>
        </a>
        <a href="https://www.instagram.com/escapebar.tw" target="_blank">
          <img 
            className='w-12'
            src="/img/footer-instagram.png"
          />
        </a>
      </div>
      {/* {!isLogin && (
        <div>
          <Button 
            className={classes.becomeMemberButton}
            variant="contained" 
            onClick={onOpenLogin} 
            fullWidth
          >
            一起成為高級玩家
          </Button>
        </div>
      )} */}
      <p className='text-center md:text-left font-medium'>
        聯絡我們： service@escape.bar
      </p>
    </div>
  )
};

const Copyrights = ({ isShow = false }) => {
  if (!isShow) return null;

  return (
    <div className="bg-neutral-950 px-3 md:px-0 py-2 h-20 lg:h-auto">
      <p className="text-center text-white">Copyright© 2025 {siteTitle}</p>
    </div>
  )
};

export default Footer;